import { Meta, Title } from '@solidjs/meta';
import {
	Button,
	Carousel,
	Container,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Link,
	Page,
	Picture,
	Section,
	TextLink,
} from '@troon/ui';
import { For, Show, Suspense, createMemo, createSignal, onMount } from 'solid-js';
import { Dynamic, NoHydration } from 'solid-js/web';
import { createAsync, useNavigate, useSearchParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { Hero } from '../../../../components/hero/photo';
import { TeeTimeSearchFields } from '../../../../components/tee-time-search';
import { timeframes } from '../../../../modules/date-formatting';
import { UpsellAccess } from '../../../../components/upsells/access';
import { PictureSection } from '../../../../components/layouts/picture-section';
import { gql } from '../../../../graphql';
import { cachedQuery } from '../../../../graphql/cached-get';
import { getConfigValue } from '../../../../modules/config';
import { TeeTimeSearchExplainer } from '../../../../components/tee-time-access-explainer';
import { courseTypeString } from '../../../../components/facility/type-strings';
import type { Address, FacilityLandingFragment } from '../../../../graphql';
import type { RouteDefinition } from '@solidjs/router';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	location: string;
	title: string;
	image: string;
	description?: string;
	slugs: Record<string, string>;
}>;

export default function TroonGolfLandingPages(props: Props) {
	const navigate = useNavigate();
	const [formRef, setFormRef] = createSignal<HTMLFormElement>();
	const facilities = createAsync(() => getFacilities({ slugs: Object.keys(props.slugs) }), { deferStream: true });
	const [, setSearchParams] = useSearchParams();

	const heroImage = createMemo(() => {
		let url: string | undefined;
		const fac = facilities()?.facilities.facilities;
		if (!fac) {
			return null;
		}
		while (!url) {
			url = fac[Math.floor(Math.random() * fac.length)]?.metadata?.hero?.url;
		}
		return url;
	});

	onMount(() => {
		setSearchParams({ utm_campaign: 'coop-site', utm_source: props.location.toLowerCase() });
	});

	return (
		<Suspense>
			<Show when={heroImage() && facilities()?.facilities.facilities}>
				{(facilities) => (
					<>
						<NoHydration>
							<Title>{props.title}</Title>
							<Meta name="description" content={props.description ?? ''} />
							<Hero src={heroImage()!} fullWidth>
								<Heading as="h1" class="w-full max-w-xl px-8 text-center text-white">
									<Picture
										src={`${getConfigValue('IMAGE_HOST')}/digital/logos/${props.image}`}
										alt={`Troon Golf ${props.location}`}
										crop="center"
										mode="contain"
										class="mb-16 w-full"
										sizes={[
											[300, 100],
											[600, 200],
										]}
									/>
									<span class="sr-only">{props.title}</span>
								</Heading>
							</Hero>
						</NoHydration>

						<Container class="relative -top-24 z-30 -mb-24">
							<form
								method="get"
								action="/tee-times"
								ref={setFormRef}
								class="flex flex-col rounded bg-white p-4 shadow-lg"
								onSubmit={(e) => {
									e.preventDefault();
									const data = new FormData(formRef());
									const [startAt, endAt] = timeframes[data.get('time') as keyof typeof timeframes] ?? [0, 24];
									if (startAt && endAt) {
										data.set('startAt', `${startAt}`);
										data.set('endAt', `${endAt}`);
									}
									data.delete('time');
									data.set('query', '');
									data.set(
										'facilities',
										Object.values(facilities())
											.map((f) => f.slug)
											.join(','),
									);
									const params = new URLSearchParams(Object.fromEntries(data) as Record<string, string>);
									navigate(`/tee-times/?${params.toString()}`);
								}}
							>
								<div class="flex flex-row flex-wrap items-stretch xl:flex-nowrap">
									<TeeTimeSearchFields hideQueryInput />
								</div>
								<TeeTimeSearchExplainer />
							</form>
						</Container>

						<Page>
							<Section>
								<Container>
									<PictureSection src={heroImage()!}>
										{props.children}
										<div>
											<Button as={Link} href="#courses" appearance="secondary" class="size-fit">
												Explore courses
											</Button>
										</div>
									</PictureSection>
								</Container>
							</Section>

							<div class="border-y border-y-neutral">
								<Carousel itemClass="w-fit lg:w-fit min-w-fit border-s border-neutral last:border-e">
									<For each={facilities() as Array<FacilityLandingFragment>}>
										{(facility) => (
											<Show when={facility.metadata?.logo?.url}>
												{(url) => (
													<Carousel.Item>
														<div class="px-16 py-8">
															<Picture
																class="w-fit"
																src={url()}
																sizes={[[200, 200]]}
																mode="contain"
																crop="center"
																alt={facility.name}
															/>
														</div>
													</Carousel.Item>
												)}
											</Show>
										)}
									</For>
								</Carousel>
							</div>

							<Container>
								<Heading id="courses" as="h2">
									Explore {props.location} courses
								</Heading>
								<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-8">
									<For
										each={
											(facilities() as Array<FacilityLandingFragment>).sort((a, b) =>
												compare(a.name, b.name),
											) as Array<FacilityLandingFragment> as Array<FacilityLandingFragment>
										}
									>
										{(facility) => (
											<div class="relative">
												<article class="group relative">
													{facility.metadata?.hero?.url ? (
														<div class="mb-2 aspect-video overflow-hidden rounded">
															<Picture
																src={facility.metadata?.hero?.url ?? ''}
																sizes={[
																	[320, 180],
																	[400, 225],
																	[500, 281],
																]}
																alt={`Photo from the golf course at ${facility.name}`}
																width={500}
																height={281}
																class="size-full rounded bg-neutral-500 object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
																loading="lazy"
															/>
														</div>
													) : (
														<div class="mb-2 aspect-video w-full rounded bg-neutral-500" />
													)}
													<Dynamic component="h2" class="text-base font-semibold">
														<Dialog key="non-bookable-facility">
															<DialogTrigger
																appearance="primary"
																as={Link}
																href={`/course/${facility.slug}`}
																class="transition-colors duration-200 after:absolute after:inset-0 after:z-0 group-hover:text-brand-500"
															>
																{facility.name}
															</DialogTrigger>
															<DialogContent header={facility.name} headerLevel="h2">
																<div class="flex flex-col gap-4">
																	<Picture
																		src={facility.metadata?.hero?.url}
																		sizes={[[520, 153]]}
																		alt=""
																		width={520}
																		height={153}
																		class="overflow-hidden rounded"
																	/>
																	<div class="flex flex-col gap-1">
																		<p class="text-xl font-semibold">{facility.name}</p>
																		<p class="order-first text-sm uppercase text-neutral-800">
																			<span class="sr-only">Course type:</span> {courseTypeString[facility.type]}
																		</p>
																		<Show when={facility.metadata?.address as Address}>
																			{(address) => (
																				<address
																					class="text-sm not-italic text-neutral-800"
																					aria-label={`Address for ${facility.name}`}
																				>
																					{[
																						address().street,
																						address().city,
																						[address().state, address().postalCode].join(' '),
																					].join(', ')}
																				</address>
																			)}
																		</Show>
																	</div>

																	<Button as={Link} href={`/course/${facility.slug}/reserve-tee-time`}>
																		Reserve a Tee Time
																	</Button>

																	<Show when={props.slugs[facility.slug]}>
																		{(phone) => (
																			<div class="relative flex justify-between rounded border border-neutral px-4 py-6">
																				<div class="flex flex-col">
																					<strong>Phone</strong>
																					<TextLink href={`tel:${phone()}`} class="after:absolute after:inset-0">
																						{phone()}
																					</TextLink>
																				</div>
																				<span class="flex size-10 items-center justify-center rounded bg-brand-100 p-1 text-xl text-brand">
																					<Icon name="phone" />
																				</span>
																			</div>
																		)}
																	</Show>
																</div>
															</DialogContent>
														</Dialog>
													</Dynamic>
													<Show when={facility.metadata?.address?.city || facility.metadata?.address?.state}>
														<p class="flex flex-row gap-2 text-sm font-medium text-neutral-800">
															<span>
																<Show when={facility.metadata?.address?.city}>
																	{facility.metadata?.address?.city},{' '}
																</Show>
																{facility.metadata?.address?.state}
															</span>
															•
															<span class="relative">
																{' '}
																Ph:{' '}
																<TextLink
																	class="z-10 after:absolute after:-inset-2"
																	href={`tel:${props.slugs[facility.slug]}`}
																>
																	{props.slugs[facility.slug]}
																</TextLink>
															</span>
														</p>
													</Show>
												</article>
											</div>
										)}
									</For>
								</div>
							</Container>

							<Container>
								<UpsellAccess location={`Co-op site: ${props.location}`} />
							</Container>
						</Page>
					</>
				)}
			</Show>
		</Suspense>
	);
}

const compare = new Intl.Collator('en').compare;

const query = gql(`query landingPageFacilities($slugs: [String!]) {
	facilities: facilitiesV3(idOrSlugs: $slugs, sortBy: name) {
    facilities {
      ...FacilityLanding
    }
  }
}
fragment FacilityLanding on Facility {
  slug
  name
  ...FacilityCard
	type
  metadata {
    hero { url }
    logo { url }
    address {
			street
      city
      state
			postalCode
    }
  }
}`);

const getFacilities = cachedQuery(query);

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
